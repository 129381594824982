import React from 'react';
import { NavLink, useLocation } from "react-router-dom";
import NavItem from './NavItem';

const Navbar = () => {
  return (
    <div>
      <nav className="navbar bg-dark nav-back navbar-expand-lg bg-body-tertiary p-0" data-bs-theme="dark" >
        <div className="container-fluid">
          <a className="navbar-brand" href="/main/home">
            <img src='/logo.png' className='brand-logo' />
          </a>
          
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse nav-pd" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0 nav-fill w-100">

              <NavItem to={`/main/storage`} className="nav-link">
                STORAGE & CARES
              </NavItem>

              <NavItem to={`/main/consignment`} className="nav-link">
                CONSIGNMENT & AUCTION
              </NavItem>

              <NavItem to={`/main/car-list`} className="nav-link">
                CAR LIST
              </NavItem>

              <NavItem to={`/main/news`} className="nav-link">
                NEWS
              </NavItem>

              <NavItem to={`/main/about`} className="nav-link">
                ABOUT
              </NavItem>

              <NavItem to={`/main/contact`} className="nav-link">
                CONTACT
              </NavItem>

            </ul>
          </div>
        </div>
      </nav>
    </div >

  );
};

export default Navbar;