import React, { useEffect, useState } from 'react'
import CarCard from './CarCard'
import { fetchExcel, filterRecords, parseExcelData } from '../../shared/util';
import Loader from '../../shared/Loader';
import uniq from "lodash/uniq";
import sortBy from "lodash/sortBy";
import * as XLSX from 'xlsx';


const _filtersInit = {
  brand: [],
  color: [],
  left_right_hand_wheel: []
}

const _selectedFilter = {
  brand: '',
  color: '',
  left_right_hand_wheel: ''
}

const allowedFilters = [
  'brand',
  'color',
  'left_right_hand_wheel'
]

const allowedSorts = [
  {
    'name': 'Color',
    'value': 'color'
  },
  {
    'name': 'Brand',
    'value': 'brand'
  },
  {
    'name': 'Price',
    'value': 'price_amount'
  }
]

const CarFilter = props => {
  const fieldName = field => field.split('_').join(' ')
  return (
    <div className='text-center'>
      <i className="fa fa-filter fa-ring text-white sidewise pull-left" aria-hidden="true" ></i>
      <select className="form-select pull-right selectpicker sidewise" onChange={(e) => props.onChange(props.field, e.target.value)} >
        <option value=''>All {fieldName(props.field)}s</option>
        {
          props.filters.map(field => (
            <option value={field}>{field}</option>
          ))
        }
      </select>
    </div>
  )
}

const CarList = () => {

  const [sort, setSort] = useState('')
  const [loading, setLoading] = useState(true)
  const [cars, setCars] = useState([])
  const [filteredCars, setFilteredCars] = useState([])
  const [filters, setFilters] = useState(JSON.parse(JSON.stringify(_filtersInit)))
  const [selectedFilter, setSelectedFilter] = useState(JSON.parse(JSON.stringify(_selectedFilter)))


  const setPossibleFilters = cars => {
    const filters = JSON.parse(JSON.stringify(_filtersInit))
    Object.keys(_filtersInit).forEach(field => {
      const values = uniq(cars.map(car => car[field])).sort()
      filters[field] = values
    })
    setFilters(filters)
  }

  const fetchCarData = async () => {
    const jsonData = await fetchExcel(process.env.REACT_APP_CAR_LIST)
    const cars = parseExcelData(jsonData)
    cacheImages(cars.map(car => car.image_1));
    setCars(cars)
    setFilteredCars(cars)
    setPossibleFilters(cars)
    setLoading(false)
  }

  useEffect(() => { fetchCarData() }, [])
  useEffect(() => { sortData() }, [sort])

  /**
   * The function `cacheImages` asynchronously loads and caches an array of images.
   */
  const cacheImages = async imageArray => {
    const promises = await imageArray.map(image => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = image
        image.onLoad = resolve();
        image.onError = reject();
      })
    })
    await Promise.all(promises)
    console.log("====== LOADED ALL IMAGES ======");
  }

  /**
   * The function `updateFilters` updates the selected filter, filters the records based on the new
   * filter, sorts the filtered records if a sort option is selected, and updates the filtered cars
   * state.
   * @param field - The "field" parameter represents the specific field or property in the
   * selectedFilter object that needs to be updated. It is a string value that indicates which field is
   * being updated.
   * @param value - The "value" parameter in the "updateFilters" function represents the new value that
   * is selected or entered for a specific filter field. It is used to update the value of the selected
   * filter in the "newFilter" object.
   */
  const updateFilters = (field, value) => {
    const newFilter = { ...selectedFilter }
    newFilter[field] = value
    setSelectedFilter(newFilter)
    let filteredRecords = filterRecords(cars, newFilter)
    if (sort.length !== 0 && filteredRecords.length > 0)
      filteredRecords = sortBy(filteredRecords, [sort])
    setFilteredCars(filteredRecords)
  }

  const sortData = () => {
    if (sort.length !== 0) {
      setFilteredCars([])
      let recs = JSON.parse(JSON.stringify(filteredCars))
      recs = sortBy(recs, [sort])
      const newCarList = JSON.parse(JSON.stringify(recs))
      setFilteredCars(newCarList)
    }
  }

  return (
    <div className='primary-back px-5' >
      <div className='section-title car-page-title' >
        Cars for sale
        <div className='pull-right desktop-only'>
          <div className='text-end car-list-filter' style={{ width: '500px' }}>
            <i className="fa fa-ring fa-filter fa-sort-amount-desc text-white sidewise pull-left" aria-hidden="true" ></i>
            <select className="form-select pull-right selectpicker sidewise" onChange={(e) => setSort(e.target.value)} >
              <option value=''>Select Sort</option>
              {
                allowedSorts.map(sort => (
                  <option value={sort.value}>{sort.name}</option>
                ))
              }
            </select>
          </div>
        </div>
      </div>
      {
        !loading && (
          <div >

            <div className='row mb-5 car-list-filter'>
              {
                allowedFilters.map((filter, idx) => (
                  <div className='col-md-4 px-3'>
                    <CarFilter filters={filters[filter]} onChange={updateFilters} field={filter} key={idx} />
                  </div>
                ))
              }
              <div className='col-md-4 px-3 mobile-only'>
                <i className="fa fa-ring fa-filter fa-sort-amount-desc text-white sidewise pull-left" aria-hidden="true" ></i>
                <select className="form-select pull-right selectpicker sidewise" onChange={(e) => setSort(e.target.value)} >
                  <option value=''>Select Sort</option>
                  {
                    allowedSorts.map(sort => (
                      <option value={sort.value}>{sort.name}</option>
                    ))
                  }
                </select>
              </div>
            </div>

            <div className='row pb-5'  >
              {
                filteredCars.map(car => (
                  <div className='col-md-4 col-sm-12' >
                    <CarCard car={car} />
                  </div>
                ))
              }
              {
                filteredCars.length === 0 && (
                  <div className='text-white text-center my-5'>
                    No cars were found
                  </div>
                )
              }
            </div>

          </div>
        )
      }
      {
        loading && <Loader />
      }
    </div >
  )
}

export default CarList