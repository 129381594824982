import './contact.css'
import React, { useEffect } from 'react'
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
const DEFAULT_ZOOM = 18
const MapsComponent = () => {

  const center = {
    lat: parseFloat(process.env.REACT_APP_ADDRESS_LAT),
    lng: parseFloat(process.env.REACT_APP_ADDRESS_LONG)
  };


  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
  })

  const [map, setMap] = React.useState(null)

  useEffect(() => {
    setTimeout(() => { map?.setZoom(DEFAULT_ZOOM) }, 1000)
  }, [map])

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);
    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  return isLoaded ? (
    <GoogleMap
      mapContainerClassName='mapLoc'
      center={center}
      zoom={DEFAULT_ZOOM}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      <Marker position={center} />
    </GoogleMap>
  ) : <></>
}


export default MapsComponent