import React from 'react';
import SocialIcon from './SocialIcon';
import { path } from '../util/config';

function Footer(props) {

  return (
    <footer className="footer text-center text-lg-start bg-light text-muted pt-5">

      <section className="">
        <div className="container text-center text-md-start text-white">
          <div className="row ">
            <div className="col-md-2 col-lg-4 mx-auto mb-4 py-5">
              <img src='/logo-t.png' width={'50%'} alt="mainLogo"/>
            </div>

            <div className="col-md-4 col-lg-4 mx-auto mb-4">
              <h6 className="footer-title fw-bold mb-4">
                Quick Access
              </h6>
              <p className='footer-link'>
                <a href={`/main${path.contact}`} className="text-reset">Contact</a>
              </p>
              <p className='footer-link'>
                <a href={`/main${path.about}`} className="text-reset">About</a>
              </p>
              <p className='footer-link'>
                <a href={`/main${path.carList}`} className="text-reset">Car List</a>
              </p>
              <p className='footer-link'>
                <a href={`/main${path.storage}`} className="text-reset">Storage & Cares</a>
              </p>
              <p className='footer-link'>
                <a href={`/main${path.consignment}`} className="text-reset">Consignment</a>
              </p>
              <p className='footer-link'>
                <a href={`/main${path.news}`} className="text-reset">News</a>
              </p>
            </div>


            <div className="col-md-4 col-lg-4 mx-auto mb-4">
              <h6 className="footer-title fw-bold mb-4">
                Reach Out
              </h6>
              <p className='footer-link'>
                {/* <a href="/main/contact" className="text-reset footer-link">Contact</a> */}
              </p>
              <p className='footer-link'>
                <i className="fa-ring fa fa-envelope-o" aria-hidden="true"></i>  enquiry@lpa.asia
              </p>
              <p className='footer-link'>
                <i className="fa-ring fa fa-phone" aria-hidden="true"></i> {process.env.REACT_APP_PHONE}
              </p>
              <p className='footer-link'>
                <i className="fa-ring fa fa-map-marker" aria-hidden="true"></i>
                &nbsp; {process.env.REACT_APP_ADDRESS}
              </p>
              <hr className='my-4' />
              <div className='social-container mx-5 px-5 '>
                <SocialIcon className="" src="/img/instagram.png" url={process.env.REACT_APP_SOCIAL_INSTAGRAM} />
                <SocialIcon className="" src="/img/whatsapp.png" url={process.env.REACT_APP_SOCIAL_WHATSAPP} />
                <SocialIcon className="" src="/img/facebook.png" url={process.env.REACT_APP_SOCIAL_FACEBOOK} />
              </div>
            </div>

          </div>
        </div>
      </section>

      <div className='bottom-footer py-4 px-5'>
        League of Prezioso Auto Limited ("LPA") 2023 | All rights reserved
      </div>

    </footer>

  );
}

export default Footer;