import React, { useState } from 'react'
import LazyImage from '../../shared/LazyImage'
import ImagePreviewModal from './ImagePreviewModal';

const CarGallery = props => {

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const openModal = (imageUrl) => {
    setSelectedImage(imageUrl);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setSelectedImage(null);
    setModalIsOpen(false);
  };

  return (
    <div className='row'>
      {
        props.images.map(car => (
          <div className='col-md-6 p-3' onClick={() => openModal(car)}>
            <LazyImage src={car} className="car-detail-img" />
          </div>
        ))
      }
      <ImagePreviewModal
        isOpen={modalIsOpen}
        closeModal={closeModal}
        imageUrl={selectedImage}
      />
    </div>
  )
}

export default CarGallery