
const path = {
  home: '/home',
  storage: '/storage',
  about: '/about',
  consignment: '/consignment',
  contact: '/contact',
  news: '/news',
  newsDetails: '/news-details',
  carList: '/car-list',
  carDetails: '/car-details'
}

const homeCarouselList = [
  {
    'type': 'image',
    'url': '/img/home/home-banner-1.png'
  },
  {
    'type': 'image',
    'url': '/img/home/home-car-2.png'
  }, {
    'type': 'image',
    'url': '/img/home/home-car-3.png'
  },
  {
    'type': 'video',
    'url': 'https://storage.googleapis.com/inertia-lpa-public-assets/TestTest.mp4'
  }]

module.exports = {
  path,
  homeCarouselList
}