import React, { useState } from 'react';
import Modal from 'react-modal';
import './CarList.css'; // Create this CSS file for styling

const ImagePreviewModal = ({ isOpen, closeModal, imageUrl }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel="CarImage"
      className="modal-preview"
    >
      <button className="close-button" onClick={closeModal}>
        Close
      </button>
      <img src={imageUrl} alt="CarImage" className="modal-image" />
    </Modal>
  );
};

export default ImagePreviewModal;
