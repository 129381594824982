import React, { useState } from 'react';
import Modal from 'react-modal';
import './Consignment.css'; // Create this CSS file for styling

const TermsPreviewModal = ({ isOpen, closeModal }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel="CarImage"
      className="modal-terms-preview"
    >
      <button className="close-button" onClick={closeModal}>
        Close
      </button>
      <div className='term-content' >
        {/* <header>
          <h1>Terms and Condition</h1>
        </header>
        <section>
          <h2>1. Introduction</h2>
          <p>These terms and conditions govern the use of the exotic car sales service provided by [Your Company Name] (hereinafter referred to as "the Company").</p>
        </section>
        <section>
          <h2>2. Acceptance of Terms</h2>
          <p>By using our services, you agree to comply with and be bound by these terms and conditions. If you do not agree to these terms and conditions, please do not use our services.</p>
        </section>
        <section>
          <h2>Contact Information</h2>
          <p>For questions, concerns, or inquiries related to these terms and conditions, please contact us at [Contact Information].</p>
        </section> */}
        <header>
          <h1>Terms and Condition</h1>
        </header>
        <p>
          Temp lorem lpsum
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam at odio mi. In nisi ligula, accumsan sit amet tincidunt non, ultrices eu purus. In sed arcu in sapien molestie sagittis nec id velit. Sed sed semper ligula, sit amet efficitur mi. Nunc quam velit, pretium tincidunt mi in, suscipit efficitur odio. Maecenas mattis placerat tellus a dictum. Vivamus in ex purus. Duis consequat felis ac sapien iaculis finibus. Vestibulum consequat eros sed ante luctus, a mattis ex varius. Praesent tempus porta imperdiet. Sed at turpis nulla. Curabitur tellus ante, dapibus nec nulla a, commodo iaculis ipsum. Sed vestibulum nunc eu efficitur sollicitudin. Pellentesque nibh ipsum, vulputate at dapibus quis, iaculis non turpis.
          Sed pulvinar quis tellus ut aliquam. Praesent odio felis, porttitor vitae feugiat at, aliquet feugiat est. Etiam porttitor consequat nisl. Mauris ut lacinia neque. Phasellus vel nisl fringilla, eleifend lectus ac, varius dolor. Quisque cursus congue arcu dignissim venenatis. Praesent nec tortor dignissim, pretium sapien eget, tempor nunc. Pellentesque tristique lectus ut eleifend pharetra. Donec lectus tellus, tempus et ex et, consequat ullamcorper felis. Ut laoreet tempor risus, eu facilisis turpis aliquet a. Ut non tellus ex. Curabitur eu sem sit amet enim condimentum interdum at ac lectus. Sed malesuada viverra ultricies. Nunc et consequat eros. Pellentesque ultrices dui nec lacus fermentum condimentum. Proin non enim luctus, convallis nisl auctor, rhoncus mauris.
          Fusce mattis mi efficitur porta dignissim. Proin laoreet, nisi a consectetur condimentum, libero odio tempor diam, sed suscipit odio nisi cursus diam. Proin et molestie lectus. Cras pharetra nec libero blandit pretium. Maecenas tempor quis nunc vitae scelerisque. Aliquam id justo dignissim, facilisis lorem et, consectetur nulla. Quisque sapien mi, tincidunt sed odio id, accumsan imperdiet tortor. Donec feugiat vel lorem eu convallis. Sed at molestie mi. Morbi tortor libero, tincidunt malesuada magna mollis, vulputate vehicula ante. Sed vel libero vitae arcu ornare elementum.
          Sed sit amet bibendum est. Pellentesque sodales, metus non consectetur varius, eros quam fringilla mauris, a auctor lectus mi in nibh. Mauris malesuada tellus ac porttitor lobortis. Nullam in dapibus libero. Phasellus cursus dui eget velit aliquam, in lacinia enim molestie. Proin hendrerit lacinia odio vitae rutrum. Quisque et aliquam massa. Mauris id ex fermentum, elementum massa id, semper ligula. Pellentesque tellus lacus, finibus sit amet risus eu, varius dictum massa. Vivamus lacus quam, lacinia vitae scelerisque vel, vestibulum sed elit. Proin interdum risus in nibh condimentum, quis hendrerit lectus congue. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.
          Vivamus at lacus in dolor scelerisque fermentum maximus id orci. Fusce ac nibh et orci gravida congue. Phasellus vehicula ornare ex. In molestie urna tortor, vel sagittis mauris ornare vel. Etiam sed quam non arcu cursus varius. Cras nec neque nec ligula iaculis rutrum eget ac urna. Praesent eget sem quam.
          Nunc nec tempor enim, et ullamcorper lorem. Fusce sit amet lacus ultrices, volutpat dolor cursus, tempor nisl. Donec fringilla hendrerit porta. Cras sed accumsan dolor. Nulla condimentum, lectus at tristique scelerisque, ante turpis mollis ante, sed suscipit lorem felis ut nunc. Aliquam interdum, sapien ut consequat volutpat, mi nisi vehicula enim, ac lobortis turpis augue congue nulla. Cras sed cursus augue. In pharetra justo at mi vulputate, a molestie tortor vestibulum.
          Nunc vel consequat augue. Vivamus at libero nisi. Nam in accumsan elit. Vestibulum vitae mauris dictum, finibus enim nec, bibendum nulla. Pellentesque bibendum dui non efficitur condimentum. Ut ac semper dui. Nulla congue risus sed sem congue rhoncus. Etiam semper massa velit, quis congue urna dignissim vitae. Phasellus ultrices auctor felis, id varius nisl auctor sit amet. Sed rutrum sem eget consectetur cursus. Ut rhoncus lorem eros, et fringilla lorem dapibus vel. Mauris condimentum neque augue, vitae feugiat quam pulvinar sed. Vivamus at purus dolor. Quisque quis faucibus lorem, at rutrum leo. Phasellus placerat posuere bibendum. Suspendisse eget tincidunt dolor, eu laoreet sapien.
          Proin sed eros purus. Donec ultricies, dolor quis bibendum sollicitudin, odio libero consectetur augue, in iaculis metus eros eget nisl. Suspendisse risus eros, iaculis sed faucibus eget, aliquet sed nunc. Suspendisse at dui lorem. Sed bibendum pellentesque nibh quis iaculis. Etiam et vestibulum nibh. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Fusce tincidunt nibh at porta aliquam. Aliquam aliquet urna ut consequat imperdiet. Nullam sit amet velit congue, tempus justo id, consequat odio. Cras ac lacus est. Morbi massa turpis, bibendum eget convallis eu, suscipit non leo. Ut cursus luctus sagittis.
          Nulla sed ligula sit amet est facilisis ornare. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Vivamus id ante a velit imperdiet dignissim. Phasellus aliquam ipsum urna, ac facilisis orci blandit eget. Donec et arcu at metus aliquam sollicitudin. Cras imperdiet odio id rutrum convallis. Phasellus ut neque suscipit mauris eleifend molestie. Sed interdum risus nulla, vel ultrices libero porta varius. Morbi mattis enim at ligula dictum, nec ultricies leo molestie. Phasellus egestas pellentesque turpis sagittis lobortis. Maecenas vel nibh at augue venenatis aliquam sed id diam. Donec cursus volutpat tempus. Suspendisse potenti.
          Suspendisse sapien lorem, iaculis non est at, tempor imperdiet purus. Nunc semper tortor sit amet lorem scelerisque vulputate. Vestibulum commodo efficitur est eu accumsan. Praesent et velit nec arcu scelerisque sollicitudin. Maecenas volutpat dui quis venenatis eleifend. Aliquam finibus finibus ligula, at facilisis nulla. Maecenas mauris enim, commodo eget ultrices et, sollicitudin ac urna. Phasellus eget libero lacus. Vestibulum et semper ante. Ut id diam ornare, vulputate odio vitae, dapibus quam. Aenean congue ipsum vel augue sodales vehicula.
          Donec dolor justo, tempor nec ipsum ac, ornare volutpat ipsum. Quisque a ligula id massa tincidunt tincidunt. Curabitur posuere nisl nec commodo dictum. Fusce quis fringilla nisl. Ut luctus leo vel turpis dictum vestibulum. Cras suscipit sem tellus, in aliquet nulla volutpat ut. Aenean quis eros varius, interdum elit quis, tempor risus. Sed luctus ultricies mauris in sodales.
          Donec urna diam, interdum mollis nunc a, volutpat suscipit enim. Donec lacinia nisi sem, at venenatis nisi commodo a. Pellentesque ut volutpat est, a tristique nunc. Suspendisse iaculis ultricies ligula non blandit. In consequat leo et scelerisque accumsan. Proin auctor molestie sollicitudin. Vestibulum nulla nulla, efficitur ac iaculis at, euismod eget nisi.
          Nunc pharetra dolor eu magna sodales ultricies consectetur eget erat. Duis tincidunt est et erat fermentum maximus. Morbi ut metus vitae mauris convallis bibendum. Duis rhoncus vulputate tortor. Integer condimentum congue tempus. Etiam gravida sem ac malesuada placerat. Morbi vulputate hendrerit purus.
          Sed ligula arcu, convallis sed enim id, sollicitudin maximus sem. Morbi pretium mi vitae ante ornare dignissim. Quisque laoreet erat quis odio ullamcorper imperdiet. Sed vehicula tincidunt leo ac sollicitudin. Donec ultricies consequat enim, sed luctus lacus laoreet vel. Suspendisse et eleifend sapien, et sagittis massa. Nam condimentum eleifend massa, a posuere eros semper non. Quisque id pulvinar lorem. Aenean bibendum rhoncus nunc non blandit. Curabitur sem enim, suscipit nec libero in, facilisis interdum erat. Donec justo metus, ullamcorper quis scelerisque ut, pretium non lectus. Praesent eget risus ac ipsum egestas condimentum. Sed nibh justo, fermentum ac ipsum aliquam, tristique malesuada ante. Curabitur libero ligula, rhoncus vel luctus at, suscipit at erat. Phasellus quam leo, feugiat sit amet pharetra et, iaculis vitae magna. Nulla ex elit, placerat non eros eget, laoreet lobortis libero.
          Praesent rutrum eget turpis eget ultricies. Maecenas scelerisque sed turpis auctor volutpat. Proin et faucibus sapien, at feugiat nunc. Nulla quam leo, venenatis vitae tellus nec, consequat aliquet nibh. Fusce viverra suscipit sollicitudin. Aliquam eu condimentum libero, a congue risus. Vestibulum eu aliquet urna. Etiam quis consectetur ex, ut ultricies turpis. Nullam congue, neque id pharetra tristique, felis elit sagittis arcu, varius hendrerit dolor neque vel neque. Quisque blandit enim ac aliquam efficitur. Sed feugiat at lorem in ultricies. Phasellus at libero euismod, ornare arcu vitae, sodales arcu. Morbi sed tempus elit. Pellentesque non aliquet eros.
          Phasellus varius nisi enim, vitae imperdiet ligula luctus et. Aenean egestas nisi sit amet justo facilisis egestas. Nullam malesuada imperdiet lorem, sit amet rutrum ex lobortis quis. Aenean porttitor vestibulum vulputate. Sed vitae nunc vel mauris faucibus tempus vel ac felis. Suspendisse tempor gravida suscipit. Integer non pharetra massa, sit amet hendrerit diam. Sed fermentum suscipit pretium.
          Aliquam volutpat leo nec congue egestas. Suspendisse dignissim, elit ac cursus mollis, diam lacus egestas dui, eget eleifend enim leo viverra nibh. Curabitur congue ipsum sed viverra accumsan. Phasellus sagittis commodo risus. Cras ac metus et libero facilisis pharetra a nec ex. Ut sodales posuere blandit. Aenean malesuada facilisis velit, sed accumsan nunc vehicula ac. Aenean vel magna suscipit, venenatis lorem in, hendrerit massa. Duis a aliquet ante. Aenean venenatis lorem vel lorem pretium, sed porta risus faucibus. Suspendisse rhoncus tincidunt mi. Etiam ac mauris imperdiet ante tempor dapibus at sed purus. Praesent egestas nibh at maximus imperdiet. Nunc non arcu nisl. Fusce et massa aliquet, eleifend purus id, iaculis leo. Fusce est dui, mollis vitae sagittis at, luctus at odio.
          Donec tellus augue, faucibus quis felis eu, consequat dictum purus. Donec placerat quis ante vel porta. Nunc consectetur pretium egestas. Curabitur suscipit ligula et leo ultrices condimentum. Donec est metus, condimentum quis leo non, porta volutpat velit. Praesent eu arcu a quam tincidunt lobortis. Ut vitae tellus lacus. Duis purus ex, congue a lectus in, semper fermentum mauris. Vivamus eget lectus sed arcu cursus commodo eu eget urna. Fusce a felis dolor. Fusce vitae magna sodales, egestas mauris ac, interdum lacus. Aenean convallis cursus ligula, vel ornare sem laoreet in. Donec pellentesque pharetra urna, nec imperdiet sem vehicula et. Curabitur euismod, leo malesuada consectetur ultrices, massa est imperdiet neque, eu sollicitudin erat justo nec enim. In posuere justo vel ipsum imperdiet, lobortis convallis neque tempus.
          Vestibulum egestas rutrum enim, tincidunt maximus massa elementum ut. In vestibulum diam at nunc fringilla egestas. Pellentesque at aliquet turpis. Integer nec ligula ac enim consequat aliquet. Aliquam erat volutpat. Aenean non justo non nisl ultricies suscipit a in ante. Morbi mollis ex vel eros finibus consequat. Aenean lobortis nibh eu nibh condimentum, quis pretium ligula vulputate. Suspendisse blandit condimentum dictum. Cras enim magna, cursus sit amet vehicula sit amet, finibus ut dui. Integer quam nibh, tincidunt et nibh at, porta blandit magna. Curabitur commodo, lacus sed laoreet cursus, enim lorem elementum magna, ut imperdiet nunc dui vitae mi. Nam eu est risus. Suspendisse lacinia consequat nisi, sit amet dictum ipsum molestie vel.
          Nunc eget eros vehicula, venenatis quam non, sodales metus. Nulla imperdiet justo ultricies dolor dignissim, in aliquet sem sollicitudin. Nam mauris dolor, vehicula ac lorem imperdiet, accumsan mattis ante. Morbi tellus arcu, fermentum tristique urna ac, interdum volutpat enim. Nullam et magna id massa consequat venenatis a a metus. Cras quis nulla viverra, semper nisl vel, sodales augue. Integer efficitur pellentesque finibus. In vel nisi sit amet magna ullamcorper ornare non a elit. Nunc in gravida enim, et egestas enim. Praesent ut dolor sagittis, blandit odio id, condimentum elit. Aenean tincidunt ultrices faucibus. Curabitur sit amet felis non risus auctor rhoncus ac non turpis. Nullam nec lacinia leo, id egestas arcu. Aliquam mauris est, accumsan in odio at, blandit maximus massa.
          Quisque sapien massa, congue egestas nulla at, lacinia sodales velit. Nullam ut justo turpis. Nam sit amet nisi at massa bibendum condimentum ac ut mauris. Suspendisse eget erat congue, pharetra lacus eget, pretium nibh. Sed maximus auctor enim. Integer semper, odio et fringilla imperdiet, massa magna iaculis est, eget accumsan enim lacus id ex. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nullam maximus turpis metus, nec iaculis sem viverra ut. Mauris ultrices consequat leo, vitae condimentum turpis pulvinar pharetra. Proin vitae justo fringilla, dignissim tortor in, interdum orci. Morbi laoreet, enim vitae fermentum tincidunt, nibh leo semper enim, et rutrum orci ex sit amet sapien.
          Nam fringilla, erat at hendrerit tincidunt, arcu diam commodo tellus, quis viverra lectus nibh non mauris. Ut eu luctus lacus. Pellentesque malesuada ligula ac sodales vestibulum. Sed at consequat lorem. Praesent in condimentum ex, at vehicula est. Nullam et eleifend nisl. Vivamus eros erat, pulvinar nec tincidunt eu, ultrices nec augue. Integer sit amet orci molestie, laoreet purus id, placerat leo. Quisque in metus vitae lectus tincidunt bibendum eu ac orci. Sed aliquam congue urna scelerisque semper. Nunc a turpis et dui tincidunt tristique nec quis nibh. Vestibulum blandit sapien ut mi mollis varius. Aliquam maximus elit quis orci iaculis lobortis. Nunc nec convallis dui. Phasellus nec viverra sem. Mauris cursus turpis a pellentesque dictum.
          Integer elementum nisl dui. Nulla placerat rutrum mauris, eget pretium nulla sollicitudin ac. Duis mattis est vel neque maximus convallis. In vitae enim faucibus enim pharetra blandit vel nec lacus. Suspendisse a purus mattis, cursus nibh eget, porttitor lacus. Donec accumsan ipsum nec vulputate faucibus. In euismod leo laoreet metus iaculis, eu lobortis arcu lacinia. Maecenas eu felis hendrerit, efficitur enim eget, vestibulum metus. Mauris quis aliquam ligula, vel cursus arcu.
          Aenean bibendum mi ac justo congue condimentum. In quam nulla, blandit et tincidunt eget, maximus sit amet arcu. Vivamus at placerat mauris, at varius lacus. Proin ut purus sit amet justo finibus sodales in non metus. Cras non ante id massa congue iaculis. Mauris eu lobortis nunc. Nam a sodales urna. In lobortis quam nec luctus pellentesque. Aenean nec nulla cursus elit lacinia ullamcorper. Sed ut mi enim. Morbi mattis purus ut sapien eleifend molestie. Aliquam sit amet hendrerit est. Etiam in eros convallis, blandit nisl id, venenatis nunc. Duis eget mauris quam. Donec at ante orci.
          Phasellus maximus maximus ultrices. Pellentesque quis mi convallis, ullamcorper sem et, commodo urna. Nulla viverra leo non arcu malesuada facilisis. Etiam egestas odio sed purus luctus, a gravida ante pharetra. Integer convallis ut justo ut posuere. Donec eleifend lacus quis quam suscipit feugiat sit amet id ex. Donec eleifend nulla nulla, ut efficitur diam accumsan quis. Ut vitae libero at orci efficitur mollis a vitae ligula. Morbi pellentesque eros id tellus placerat, sit amet iaculis ligula tempor. Aenean suscipit nec nulla a faucibus.
          Curabitur dictum erat vitae erat faucibus, ac porttitor ex sodales. Suspendisse malesuada elit ligula, vitae auctor nulla scelerisque eget. Nunc volutpat nunc non nisl varius congue. Aliquam purus quam, eleifend eu aliquet eu, efficitur ut sem. Nullam pulvinar felis quis erat varius, at scelerisque mauris posuere. Vivamus sapien purus, mattis a pulvinar vel, elementum a ex. Fusce lacinia hendrerit ligula. Interdum et malesuada fames ac ante ipsum primis in faucibus. Praesent fermentum at ligula a pellentesque. Ut pretium fringilla nisi non malesuada. Phasellus gravida vulputate lorem, sit amet laoreet risus lobortis maximus.
          Sed neque sapien, porta eget dui at, sodales mattis arcu. Integer bibendum erat erat, quis rutrum odio facilisis a. Vestibulum ante tortor, ornare ac fringilla a, dignissim eu purus. Proin molestie laoreet lorem, eu consectetur ligula suscipit a. Vestibulum ultrices ipsum turpis, sed porta augue facilisis accumsan. Aenean finibus sapien pulvinar urna porta dapibus. Sed non lacus dolor. Curabitur ornare rutrum leo sit amet porta. Proin aliquet maximus pretium. Ut id turpis eu lacus dignissim convallis vitae et nunc. Phasellus ac ligula nulla. Pellentesque non viverra tellus. Donec sollicitudin egestas hendrerit.
          Sed vehicula dui a dolor mattis imperdiet. Integer non tempor metus. Nam vestibulum maximus suscipit. Nulla in commodo ipsum. Duis nec condimentum erat. Suspendisse at facilisis velit. Quisque nulla nisl, dignissim eu congue non, luctus vel arcu. Nam maximus lacus in lacus aliquam lacinia. Nulla sit amet sapien bibendum, condimentum lacus vel, ultrices eros.
          Nunc libero odio, iaculis sed molestie vitae, dapibus at leo. Curabitur vitae ornare nibh. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Praesent eleifend ex sit amet orci varius finibus. Aenean sollicitudin venenatis eros a porta. Maecenas rhoncus nisl sed quam varius, in facilisis tellus auctor. Curabitur nunc velit, volutpat a tincidunt sit amet, elementum quis nisi. Nullam pretium a quam sit amet tempor. Donec vehicula justo ac sapien consectetur facilisis. In ac orci dolor. Aliquam neque orci, facilisis eget libero et, fermentum sagittis magna. Praesent neque lacus, volutpat sed fringilla at, faucibus in ligula. Vestibulum consequat, lorem nec porta cursus, ipsum urna dapibus augue, eu luctus est turpis non urna. In venenatis, turpis ornare porttitor dictum, sem elit sollicitudin tellus, at consequat odio turpis id lorem. Aliquam erat volutpat.
          Vestibulum rhoncus tortor sit amet pharetra hendrerit. Nulla risus nulla, aliquet ac ultrices eget, porttitor nec lorem. Nulla tellus urna, convallis nec pharetra eget, semper sit amet libero. Praesent varius justo et magna maximus, id vestibulum turpis feugiat. Quisque ipsum ex, hendrerit in ultrices ac, rutrum elementum lacus. In pharetra est nisi, molestie gravida ligula tincidunt id. Vivamus rutrum sem vestibulum risus gravida, et dapibus mi sagittis. Curabitur nisi nisi, lobortis hendrerit dolor sed, consectetur ornare enim. Aenean a nisl risus. Cras dictum fermentum eros eu egestas. In hac habitasse platea dictumst. Suspendisse potenti. Vestibulum eleifend diam sed eros mollis consectetur. Fusce eu euismod odio, sed accumsan enim. Nulla finibus vel ligula vitae porta. Ut turpis ligula, facilisis a ultricies nec, maximus a ante.

        </p>
      </div>
    </Modal>
  );
};

export default TermsPreviewModal;
