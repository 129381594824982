import React from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const LazyImage = props => {

  return (
    <div style={{ height: '100%' }}>
      <LazyLoadImage
        className={props.className}
        alt={props.alt}
        placeholderSrc={'/img/placeholder-car.png'}
        height={props.height}
        src={props.src}
        style={props.style}
        width={props.width} />
    </div>
  )
}


export default LazyImage