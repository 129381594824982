import './App.css';
import Navbar from './shared/Navbar';
import Footer from './shared/Footer';
import { Navigate, Route, Routes } from 'react-router';
import Main from './components/Main';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <div className="App">
      <Navbar />
      <Routes className="">
        <Route path="/" element={<Navigate to='/main/home' />} />
        <Route path="/main/*" element={<Main />} />
      </Routes>
      <Footer />

      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        theme="dark"
      />
      <ToastContainer />

    </div>
  );
}

export default App;
