

import React from 'react'

const StorageCard = props => {
  return (
    <div className='storage-card'>
      <img src={props.src} />
      <div className='storage-card-title'>
        {props.title}
      </div>
    </div>
  )
}

export default StorageCard