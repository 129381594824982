import './HomePage.css'
import React, { useState } from 'react'
import { homeCarouselList } from '../../util/config';

const HomeCarousel = props => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div id="homeCarousel" className="carousel slide" data-bs-ride="carousel"  onSlide={setActiveIndex} data-interval="7000">

      <ol className="carousel-indicators">
        {
          homeCarouselList.map((item, idx) => (
            <li data-bs-target="#homeCarousel" data-bs-slide-to={idx} className={activeIndex === idx ? 'active' : ''}></li>
          ))
        }
      </ol>

      <div className="carousel-inner">
        {
          homeCarouselList.map((item, idx) => (
            <div className={`carousel-item ${activeIndex === idx ? 'active' : ''}`}>
              {
                item.type === 'image' && <img src={item.url} className='home-banner-image' alt="Image 1" />
              }
              {
                item.type === 'video' && (
                  <div className="video-container-carousal">
                    <video controls class="homeVideo" autoPlay muted>
                      <source src={item.url} type="video/mp4" />
                    </video>
                    <div className="video-loader">
                      <div className="loader"></div>
                    </div>
                  </div>
                )
              }
            </div>
          ))
        }
      </div>
      <a className="carousel-control-prev" href="#homeCarousel" role="button" data-bs-slide="prev">
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </a>
      <a className="carousel-control-next" href="#homeCarousel" role="button" data-bs-slide="next">
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </a>
    </div>
  );
}

export default HomeCarousel