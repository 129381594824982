import React from 'react'
import { useNavigate } from 'react-router';
import LazyImage from '../../shared/LazyImage';

const NewsCard = props => {
  const navigate = useNavigate();

  const redirect = () => {
    navigate('/main/news-details', {
      state: props.news
    });
  }
  
  return (
    <div className="card m-3" onClick={redirect}>
      <LazyImage src={props.news?.main_image} className='news-image' />
      <div className="card-body news-title">
        <strong className='font-weight-bold'>
          {props.news?.title}
        </strong>
        <p className="card-text">
          {props.news?.date}
        </p>
      </div>
    </div>
  )
}


export default NewsCard