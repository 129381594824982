import './HomePage.css'
import React from 'react'
import CarCard from './util'
import LazyImage from '../../shared/LazyImage'
import HomeCarousel from './HomeCarousel'
import { useNavigate } from 'react-router'

const Home = () => {

  const navigate = useNavigate();
  const redirectToContact = () => navigate('/main/contact')

  return (
    <div >
      <div className=''>
        <HomeCarousel />
      </div>
      <div id="mid-section">
        <div className='section-title'>
          Featured cars
        </div>
        <div className='row p-0'>
          <div className='col-md-6 col-sm-12 p-0'>
            <CarCard title='Lamborgini Countach' src='/img/home/home-car-1.png' />
          </div>
          <div className='col-md-6 col-sm-12 p-0'>
            <CarCard title='Pagani Huayra Roadster' src='/img/home/home-car-2.png' />
          </div>
          <div className='col-md-6 col-sm-12 p-0'>
            <CarCard title='Porsche GT3RS' src='/img/home/home-car-3.png' />
          </div>
          <div className='col-md-6 col-sm-12 p-0'>
            <CarCard title='Ferrari LaFerrari' src='/img/home/home-car-4.png' />
          </div>
        </div>
        <div className='section-title'>
          League of Prezioso Auto Limited
        </div>
        <div className='home-banner-image-container'>
          <LazyImage src='/img/home/home-banner-2.png' className='home-banner-image ' alt="Image Alt" loading="lazy" />
          {/* <img src='/img/home/home-banner-2.png' className='home-banner-image ' alt="Image Alt" loading="lazy" /> */}
        </div>
        <div className='text-home-primary my-5'>
          <div className='text-white'>
            League of Prezioso Auto Limited ("LPA") is
          </div>
          <div className='text-white'>
            a company dedicated to inheriting the culture of
          </div>
          <div className='text-white '>
            classic cars and rare cars, aiming to create a new
          </div>
          <div className='text-yellow'>
            automotive industry ecosystem.
          </div>
        </div>
        <div className='px-5 py-5 text-home-secondary text-white'>
          LPA is headquartered in Ap Lei Chau of Hong Kong, covering an area of ​​more than 30,000 square feet. It provides a one-stop centralized place to properly store classic cars and rare cars. On top of that, there will be the first large-scale classic car repairment and maintenance centre and training centre in Zhuhai, China, with extensive maintenance experience and systematic management. Each vehicle entering the maintenance and repairment centre will be assigned an independent room and the repairment process will be fully under monitoring and controls.
        </div>
        <div className="row py-4">
          <div className='col-md-6 col-sm-12 px-5 '>
            <LazyImage src="/img/home/home-banner-3.png" width={'100%'} height={'80%'} />
          </div>
          <div className='col-md-6 col-sm-12  p-5'>
            <div className='text-home-secondary text-white text-start'>
              In addition, LPA will also provide auction and investment service for classic cars to provide more liquidity and achieve sustainable development for classic cars. LPA will also source and introduce super/hyper cars from various countries and obtained exclusive authorization from the car owners to bring a huge supply of cars and promote the development of the auto industry in Hong Kong and to pass on the torch from generation to generation.
            </div>
            <button className='btn btn-primary-new pull-left mt-5' onClick={redirectToContact}>
              CONTACT US &nbsp;&nbsp; <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
            </button>
          </div>

        </div>
        <div>

        </div>
      </div>
    </div>
  )
}

export default Home