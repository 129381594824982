import './News.css'
import React, { useEffect, useState } from 'react'
import NewsCard from './NewsCard';
import uniq from "lodash/uniq";
import { fetchExcel, parseExcelData } from '../../shared/util';
import Loader from '../../shared/Loader';


const News = () => {
  const [cat, setCat] = useState('all');
  const [loading, setLoading] = useState(true)
  const [news, setNews] = useState([])
  const [allNews, setAllNews] = useState([])
  const [categories, setCategories] = useState([])

  const fetchNewsData = async () => {
    const jsonData = await fetchExcel(process.env.REACT_APP_NEWS_LIST)
    const newsData = parseExcelData(jsonData)
    setCategories(uniq(newsData.map(news => news.category)))
    setAllNews(newsData)
    setNews(newsData)
    setLoading(false)
  }

  const filterNews = () => {
    if (cat === 'all')
      setNews(JSON.parse(JSON.stringify(allNews)))
    else {
      const filteredNews = allNews.filter(news => news.category === cat)
      setNews(JSON.parse(JSON.stringify(filteredNews)))
    }
  }

  useEffect(() => filterNews(), [cat])
  useEffect(() => { fetchNewsData() }, [])

  return (
    <div className='news-back' >
      <div >
        <div className='row'>
          <div className='section-title col-md-6'>
            News & Upcomings
          </div>
          <div className='col-md-6 text-white pt-5'>

          </div>
        </div>
      </div>
      {
        loading && <Loader />
      }
      {
        !loading && (
          <div>
            <div className='pb-5 options'>
              < span className={"badge m-2 " + ('all' === cat ? 'badge-active' : '')} onClick={() => setCat('all')}> ALL</span>
              {
                categories.map(category => (
                  < span className={"badge m-2 " + (category === cat ? 'badge-active' : '')} onClick={() => setCat(category)}> {category.toUpperCase()}</span>
                ))
              }
            </div>
            <div className='row'>
              {
                news.map(content => (
                  <div className='col-md-6 text-white'>
                    <NewsCard news={content} />
                  </div>
                ))
              }
            </div>
          </div>
        )
      }
    </div >
  )
}

export default News