import './Storage.css'
import React from 'react'
import StorageCard from './StorageCard'
import LazyImage from '../../shared/LazyImage'
import { useNavigate } from 'react-router'


const Storage = () => {

  const navigate = useNavigate();
  const redirectToContact = () => navigate('/main/contact')
  
  return (
    <div>
      <div className="storage-banner-container">
        {/* <img src='/img/storage/storage-banner-1.png' className='storage-banner-image' alt="Image Alt" loading="lazy"/> */}
        <LazyImage src='/img/storage/storage-banner-1.png' className='storage-banner-image' alt="Image Alt" loading="lazy" />
        <div className='text-white storage-header'>
          For every car that store with us, LPA will do the best to take care of your car(s) like its own.
        </div>
      </div>
      <div id="mid-section-alt" style={{ 'padding': '0% 5%' }}>
        <div className='storage-title'>
          We will take care of your car(s)
        </div>
        <div className='storage-sub'>
          in a safe, secured, and dehumidified environment and provide the following services:
        </div>
        <div className='row mt-5 '>
          <div className='col-md-4 col-sm-6'>
            <StorageCard src='/img/storage/storage-logo.png' title='Dehumidified storage area' />
          </div>
          <div className='col-md-4 col-sm-6'>
            <StorageCard src='/img/storage/security-logo.png' title='24 hours security' />
          </div>
          <div className='col-md-4 col-sm-6'>
            <StorageCard src='/img/storage/periodic-logo.png' title='Periodic starting' />
          </div>
          <div className='col-md-4 col-sm-6'>
            <StorageCard src='/img/storage/tire-logo.png' title='Tire cushions' />
          </div>
          <div className='col-md-4 col-sm-6'>
            <StorageCard src='/img/storage/comp-logo.png' title='Complimentary car wash' />
          </div>
          <div className='col-md-4 col-sm-6'>
            <StorageCard src='/img/storage/license-logo.png' title='Licensing documentation' />
          </div>
        </div>

        <div className='storage-title mt-5'>
          General terms
        </div>
        <div className='storage-sub'>
          {/* <LazyImage src='/img/nav-icon.png' alt="Image Alt" loading="lazy" /> */}
          <img src='/img/nav-icon.png' alt="Image Alt" loading="lazy"/> &nbsp;
          <span>The storage and care service is charged on a monthly basis and security deposit is required.</span>
        </div>
        <div className='storage-sub'>
          {/* <LazyImage src='/img/nav-icon.png' alt="Image Alt" loading="lazy" />&nbsp; */}
          <img src='/img/nav-icon.png' alt="Image Alt" loading="lazy"/> &nbsp;
          No contraband in the car during the storage period is allowed.
        </div>
        <div style={{ 'height': '50px' }}></div>
      </div>

      <div className='storage-bottom row m-0'>
        <div className='col-md-6 col-sm-12' style={{ paddingLeft: '7%', paddingTop: '5%' }}>
          <div className='text-home-primary text-white text-start'>
            Store with us!
          </div>
          <div className='text-home-secondary text-white text-start mt-4'>
            Free yourself from the cumbersome matters such as renting out, hiring drivers, and scheduling!
          </div>
          <button className='btn btn-primary-new pull-left mt-5' onClick={redirectToContact}>
            CONTACT US &nbsp;&nbsp; <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
          </button>
        </div>
        <div className='col-md-6 col-sm-12 '>
        <LazyImage src="/img/storage/storage-car-1.png" width={'100%'} height={'100%'} alt="Storage Car" loading="Loading" />
          {/* <img src="/img/storage/storage-car-1.png" width={'100%'} height={'100%'} alt="Storage Car" loading="Loading" /> */}
        </div>
      </div>

    </div>
  )
}

export default Storage