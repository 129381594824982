import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { path } from '../util/config';
import Storage from './storage';
import About from './about';
import Consignment from './consignment';
import Contact from './contact';
import News from './news';
import CarList from './carList';
import Home from './home';
import NewsDetails from './news/NewsDetails';
import CarDetails from './carList/CarDetails';

const Main = () => {
  return (
    <div>
      <Routes>
        <Route path={path.home} element={<Home />} />
        <Route path={path.storage} element={<Storage />} />
        <Route path={path.about} element={<About />} />
        <Route path={path.consignment} element={<Consignment />} />
        <Route path={path.contact} element={<Contact />} />
        <Route path={path.carList} element={<CarList />} />
        <Route path={path.carDetails} element={<CarDetails />} />
        <Route path={path.news} element={<News />} />
        <Route path={path.newsDetails} element={<NewsDetails />} />
      </Routes>
    </div>
  );
};

export default Main;