import './contact.css'
import { useForm } from "react-hook-form";
import React, { useMemo, useState } from 'react'
import SocialIcon from '../../shared/SocialIcon'
import MapsComponent from './MapsComponent'
import { getContactUsForm, validateContactUsForm, saveContactUs } from '../../shared/util'
import { toast } from 'react-toastify';
import Loader from '../../shared/Loader';
import ButtonLoader from '../../shared/ButtonLoader';

const Contact = () => {

  const [loading, setLoading] = useState(false)

  const handleSubmitForm = async result => {
    if (validateContactUsForm(result) === true) {
      setLoading(true)
      saveContactUs(result)
        .then(response => response.data)
        .then(data => {
          setLoading(false)
          toast("Form submitted successfully", { type: 'success' })
          reset()
        })
        .catch(err => {
          setLoading(false)
          toast("Some error in form submission. Please try again !", { type: 'error' })
        });
    }
  }

  const { register, handleSubmit, reset } = useForm({
    defaultValues: JSON.parse(JSON.stringify(getContactUsForm('contact_us')))
  });


  return (
    <div style={{ 'backgroundColor': '#0B0B0B' }} className=''>
      <div className='row contact-top' >
        <div className='col-md-4 col-sm-12'>
          <div>
            <div className='section-title text-start'>Contact us</div>
            <div className='text-start px-3' >
              <p className='footer-link my-3'>
                <i className="fa-ring fa fa-envelope-o" aria-hidden="true"></i> &nbsp; enquiry@lpa.asia
              </p>
              <p className='footer-link my-3'>
                <i className="fa-ring fa fa-phone" aria-hidden="true"></i>&nbsp; {process.env.REACT_APP_PHONE}
              </p>
              <p className='footer-link my-3'>
                <i className="fa-ring fa fa-map-marker" aria-hidden="true"></i>
                &nbsp; &nbsp;  {process.env.REACT_APP_ADDRESS}
              </p>
            </div>
            <br></br>
            <hr className='my-3 mx-4' />
            <div className='social-container mx-5 px-5 '>
              <SocialIcon className="" src="/img/instagram.png" url={process.env.REACT_APP_SOCIAL_INSTAGRAM} />
              <SocialIcon className="" src="/img/whatsapp.png" url={process.env.REACT_APP_SOCIAL_WHATSAPP} />
              <SocialIcon className="" src="/img/facebook.png" url={process.env.REACT_APP_SOCIAL_FACEBOOK} />
            </div>
          </div>
        </div>
        <div className='col-md-8 col-sm-12 pt-5'>
          <MapsComponent />
          {/* <img src='/img/contact/map.png' width={'100%'} style={{ maxHeight: '500px' }} /> */}
        </div>
      </div>


      <div id='mid-section' className='py-4' style={{ marginTop: '70px' }}  >
        <div className='consignment-form-container px-5 py-4' >
          <div className='consignment-title'>
            Invitation to consign with us!
          </div>
          <div className='consignment-sub'>
            We invite you to share our success with the opportunity to consign with us!
          </div>
          <form onSubmit={handleSubmit(handleSubmitForm)} className='row mt-5'>
            <div className='col-md-6 mt-4'>
              <input type="text" className="form-control no-border" placeholder="Name*" {...register('name', { required: true })} />
            </div>
            <div className='col-md-6 mt-4'>
              <input type="text" className="form-control no-border" placeholder="Phone" {...register('phone', { required: false })} />
            </div>
            <div className='col-md-6 mt-4'>
              <input type="text" className="form-control no-border" placeholder="Email" {...register('email', { required: false })} />
            </div>
            <div className='col-md-6 mt-4'>
              <input type="text" className="form-control no-border" placeholder="Subject" {...register('subject', { required: false })} />
            </div>

            <div className='col-md-12 mt-5'>
              <div class="form-floating">
                <textarea class="form-control" placeholder="Message" id="messageArea" style={{ height: '100px' }} {...register('message', { required: false })}  ></textarea>
              </div>
            </div>

            <button className='btn btn-sm btn-primary-new pull-left mt-5 mx-3' style={{ 'width': '250px' }} type='submit'>
              {
                !loading && (
                  <span>
                    CONTACT US &nbsp;&nbsp; <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                  </span>
                )
              }
              {
                loading && <ButtonLoader />
              }

            </button>
          </form>
        </div>
      </div>

    </div>
  )
}

export default Contact