import LazyImage from '../../shared/LazyImage'
import './News.css'
import React, { useEffect, useState } from 'react'
import NewsCard from './NewsCard'
import { useLocation } from 'react-router'
import { fetchExcel, parseExcelData } from '../../shared/util'
import SocialIcon from '../../shared/SocialIcon'


const NewsDetails = props => {
  const { state: news } = useLocation();
  const [latestNews, setLatestNews] = useState([])

  const fetchNewsData = async () => {
    const jsonData = await fetchExcel(process.env.REACT_APP_NEWS_LIST)
    const newsData = parseExcelData(jsonData)
    setLatestNews(newsData.splice(0, 3))
    console.log(news);
  }
  useEffect(() => { fetchNewsData() }, [])

  return (
    <div>

      <div className='news-back' >

        <div className='pt-5 px-4 text-start'>
          <div className="badge" > {news.category.toUpperCase()}</div>
          <div className='mt-3 news-page-head'>
            {news.title}
          </div>
          <div className='news-page-subtitle row'>
            <span className='col-md-6 pull-left'>
              {news.date}
            </span>
            <span className='col-md-6 ' >
              {
                news.linkedin_link?.length !== 0 && 
                <SocialIcon src="/img/linkedin.png" url={news.linkedin_link} />
              }
              {
                news.twitter_link?.length !== 0 && 
                <SocialIcon src="/img/twitter.png" url={news.twitter_link} />
              }
              {
                news.link?.length !== 0 && 
                <SocialIcon src="/img/link.png" url={news.link} />
              }
            </span>
          </div>
        </div>

        <div className='px-4 pt-4'>
          <LazyImage src={news.main_image} className="news-detail-banner" />
        </div>

        <div className='new-content mt-5 mx-4'>
          <p>
            {news.content}
          </p>
        </div>


      </div>

      <div className='news-footer'>
        <div className='news-page-head text-start px-3'>
          Latest news & upcomings
        </div>
        <div className='row mt-4'>
          {
            latestNews.map(content => (
              <div className='col-md-4 text-white'>
                <NewsCard news={content} />
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default NewsDetails