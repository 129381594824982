import axios from "axios";
import { toast } from "react-toastify";
import * as XLSX from 'xlsx';

const getDriveUrl = url => {
    const contentId = url.split('/')[5]
    return `https://drive.google.com/uc?export=view&id=${contentId}`
}

export function getCarImages(car) {
    const keys = Object.keys(car).filter(key => key.includes('image_') && car[key] !== '');
    const images = keys.map(key => car[key])
        .map(url => {
            if (url.includes('drive.google')) return getDriveUrl(url)
            return url
        })
    if (images.length === 0) return [
        '/img/carDetails/car-detail-1.png',
        '/img/carDetails/car-detail-2.png',
        '/img/carDetails/car-detail-3.png',
        '/img/carDetails/car-detail-4.png',
        '/img/carDetails/car-detail-5.png'
    ]
    return images
}

export function getCarVideos(car) {
    const keys = Object.keys(car).filter(key => key.includes('video_') && car[key] !== '');
    const videos = keys.map(key => car[key])
        .map(url => {
            if (url.includes('drive.google')) return getDriveUrl(url)
            return url
        })
    if (videos.length === 0) return []
    return videos
}

export async function fetchExcel(url) {
    const headers = { 'Content-Type': 'blob' };
    const config = { method: 'GET', url, responseType: 'arraybuffer', headers };
    const response = await axios(config);
    const data = new Uint8Array(response.data);
    const workbook = XLSX.read(data, { type: 'array' });
    const firstSheetName = workbook.SheetNames[0];
    const sheet = workbook.Sheets[firstSheetName];
    const jsonData = XLSX.utils.sheet_to_json(sheet);
    return jsonData
}


export function parseExcelData(data) {
    const mapping = {}
    const fields = {}
    Object.entries(data[0]).forEach((k) => {
        if (k[0] !== '__EMPTY') {
            mapping[k[0]] = k[1]
            fields[k[1]] = ''
        }
    });
    const items = []
    data.slice(1).forEach(_data => {
        if (_data.A !== undefined) {
            const newItem = JSON.parse(JSON.stringify(fields))
            Object.entries(_data).forEach(k => {
                const field = mapping[k[0]]
                let value;
                switch (field) {
                    case 'color':
                        value = convertToLowerIfString(k[1]);
                        break;
                    default:
                        value = k[1];
                        break;
                }
                newItem[field] = value;
            })
            items.push(newItem);
        }
    })
    return items
}

export function filterRecords(_records, _filter) {
    const records = JSON.parse(JSON.stringify(_records));
    const filteredRecords = records.filter(record => {
        let status = true
        Object.entries(_filter).forEach(([k, v]) => {
            // console.log(k, v);
            if (v.length !== 0) status = (record[k] === v) && status
        })
        return status
    })
    return filteredRecords
}

export function getContactUsForm(type) {
    return {
        "type": type,
        "name": "",
        "phone": "",
        "email": "",
        "subject": "",
        "message": "",
        "vehicle_location": "",
        "service_history": ""
    }
}

export function validateContactUsForm(form) {
    let status = true;
    let message = ''
    if (form.name.length === 0) {
        status = false
        message = 'Name feld is required'
    } else if (form.phone.length === 0 && form.email.length === 0) {
        status = false
        message = 'Either provide email or phone'
    }
    if (!status) toast(message, { type: 'error' });
    return status
}

export function saveContactUs(data) {
    const url = process.env.REACT_APP_BACK_URL
    const headers = {
        'Content-Type': 'application/json',
    };

    return axios({
        url,
        method: 'post',
        headers,
        data: JSON.stringify(data)
    })


    // return fetch(url, {
    //   method: 'POST',
    //   mode: 'no-cors',
    //   headers: new Headers({
    //     'Content-Type': 'application/json'
    //   }),
    //   body: JSON.stringify(data)
    // })
}

function convertToLowerIfString(value) {
    if (value !== null && typeof value === 'string') {
        return value.toLowerCase();
    } else {
        return value;
    }
}