import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router';
import CarGallery from './CarGallery';
import { getCarImages, getCarVideos } from '../../shared/util';
import CarVideos from './CarVideos';

const CarSpec = props => (
  <div className='row mt-4'>
    <div className='col-md-6 col-lg-6 col-sm-6 field'>{props.field}</div>
    <div className='col-md-6 col-lg-6 col-sm-6 value'>
      {(props.value === undefined || props.value === '') ? '-' : props.value}
    </div>
  </div>
)

const CarDetails = props => {
  const { state } = useLocation();
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);

  const enquireNow = () => window.open(process.env.REACT_APP_SOCIAL_WHATSAPP, '_blank', 'noreferrer')

  useEffect(() => {
    setImages(getCarImages(state))
    setVideos(getCarVideos(state))
  }, [state])

  return (
    <div className='primary-back px-5' >
      <div className='car-title' style={{ paddingTop: '15%' }}>
        {state.name}
      </div>
      <div className='car-sub-title text-start'>
        <div>
          {state.short_description}
        </div>
        <div className='text-yellow row py-5 px-2'>
          <div className='col-md-6 col-sm-12 text-start'>
            {state.price_currency} &nbsp; {state.price_amount}
          </div>
          <div className='col-md-6 col-sm-12 text-end'>
            <button className='btn btn-primary-new pull-right ' onClick={enquireNow}>
              ENQUIRE NOW &nbsp;&nbsp; <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>

      <CarGallery images={images} videos={videos} />

      <div className='row py-5'>
        <div className='col-md-6'>
          <div className="accordion accordion-flush" id="accordionFlushExample">
            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingOne">
                <button className="accordion-button collapsed show" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                  Description
                </button>
              </h2>
              <div id="flush-collapseOne" className="accordion-collapse collapse show" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                <div className="accordion-body">
                  {/* <ol>
                    <li>ROSSO CORSA DS 322</li>
                    <li>NERO 8500</li>
                    <li>APAPTIVE FRONTLIGHT SYSTEM</li>
                    <li>RED BRAKE CALLIPERS</li>
                    <li>REAR CD READER</li>
                    <li>CARBON FIBRE REAR AIR-DUCTS</li>
                    <li>ELECTRIC SEATS</li>
                    <li>SUSPENSION LIFTER</li>
                    <li>CAVALLINO STITCHED ON HEADREST</li>
                    <li>HIGH EMOTION LOW EMISSION</li>
                    <li>METRIC SYSTEM INSTRUMENTS</li>
                    <li>CARB.FIB.STEERING WHEEL + LEDS</li>
                    <li>'SCUDERIA FERRARI' SHIELDS</li>
                    <li>PARKING CAMERA</li>
                    <li>FRONT AND REAR PARKING SENSORS</li>
                  </ol> */}
                  {state.long_description}
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingTwo">
                <button className="accordion-button collapsed show" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                  More infomation
                </button>
              </h2>
              <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                <div className="accordion-body">
                  {state.long_description}
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingThree">
                <button className="accordion-button collapsed show" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                  Videos
                </button>
              </h2>
              <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                <div className="accordion-body">
                  <CarVideos videos={videos} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-md-6 car-spec mt-5'>
          <div className="card text-center primary-back">
            <div className="card-header">
              SPECIFICATION
            </div>
            <div className="card-body text-white px-5">
              <CarSpec field="Year" value={state.year} />
              <CarSpec field="Mileage" value={state.mileage} />
              <CarSpec field="Exterior colour" value={state.exterior_color} />
              <CarSpec field="Interior colour" value={state.interior_color} />
              <CarSpec field="Drive type" value={state.left_right_hand_wheel} />
              {/* <CarSpec field="Engine" value={state.engine} /> */}
              <CarSpec field="Body type" value={state.body_type} />
              <CarSpec field="Transmission" value={state.transmission} />
              <CarSpec field="Specs" value={state.specs} />
              <CarSpec field="Door" value={state.door} />
              <CarSpec field="Fuel type" value={state.fuel_type} />
            </div>
            <div className="card-footer text-center">
              <button className='btn btn-primary-new my-3' onClick={enquireNow}>
                ENQUIRE NOW &nbsp;&nbsp; <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CarDetails