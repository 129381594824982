import React from 'react'

const SocialIcon = props => {
  return (
    <a href={props.url} target='_blank' rel='noreferer'>
      <img src={props.src} className='social-icon' />
    </a>
  )
}

export default SocialIcon