import { NavLink, useLocation } from "react-router-dom";

const NavItem = (props) => {
  let location = useLocation();
  const isActive = location.pathname === props.to;

  return (
    <li className="nav-item has-text-weight-semibold">
      <NavLink
        to={props.to}
        aria-expanded="false"
        className={({ isActive }) =>
          isActive ? `nav-active ${props.className}` : `${props.className}`
        }
      >
        {
          isActive && (
            <img src="/img/nav-icon.png" style={{ 'width': '15px', marginRight: '5px' }} />
          )
        }
        <span>{props.children}</span>
      </NavLink>
    </li>
  );
};

export default NavItem;
