import LazyImage from '../../shared/LazyImage';
import './About.css';
import React from 'react'

const About = () => {
  return (
    <div>

      <div className='about-car-container'>
        {/* <img src='/img/about/about-banner-1.png' className='about-banner-img' /> */}
        <LazyImage src='/img/about/about-banner-1.png' className='about-banner-img' />
        <div className='about-bottom-overlay '>
          <blockquote>
            <p>League of Prezioso Auto Limited ("LPA") is a company dedicated to inheriting the culture of classic cars and rare cars, aiming to create a new automotive industry ecosystem.</p>
          </blockquote>
        </div>
      </div>

      <div id='mid-section' style={{paddingBottom:'50px'}}>
        <div className='row pt-5'>
          <div className='col-md-6 col-sm-12' >
            <div className='about-mid-container'>
              <LazyImage className='pos-abs about-mid-image-1' src='/img/about/about-banner-back-1.png' />
              <LazyImage className='pos-abs about-mid-image-2' src='/img/about/about-car-1.png' />
              {/* <img className='pos-abs about-mid-image-1' src='/img/about/about-banner-back-1.png' />
              <img className='pos-abs about-mid-image-2' src='/img/about/about-car-1.png' /> */}
            </div>
          </div>
          <div className='col-md-6 col-sm-12 text-white text-start'>
            <p style={{ 'display': 'flex' }} className='px-5'>
              In addition, LPA will also provide auction and investment service for classic cars to provide more liquidity and achieve sustainable development for classic cars. LPA will also source and introduce super/hyper cars from various countries and obtained exclusive authorization from the car owners to bring a huge supply of cars and promote the development of the auto industry in Hong Kong and to pass on the torch from generation to generation.
            </p>
          </div>
        </div>
        <div className='row '>

          <div className='col-md-6 col-sm-12 text-white text-start'>
            <div className='section-title'>
              Our Philosophy
            </div>
            <p style={{ 'display': 'flex' }} className='px-5'>
              Hong Kong's car culture has a long history, and many car enthusiasts over the world have been following car culture of Hong Kong. The goal of LPA, with the passion from the four young dream chasers, is to connect the world with the Hong Kong car culture and bring it to the Greater Bay Area and promote the development of the Greater Bay Area and make use of the advantages of the different places to complement each other in the region to achieve multi-regional win-win situation.
            </p>
          </div>
          <div className='col-md-6 col-sm-12' >
            <div className='about-mid-container'>
              <LazyImage className='pos-abs about-mid-image-1' src='/img/about/about-banner-back-1.png' />
              <LazyImage className='pos-abs about-mid-image-2' src='/img/about/about-car-2.png' />
              {/* <img className='pos-abs about-mid-image-1' src='/img/about/about-banner-back-1.png' />
              <img className='pos-abs about-mid-image-2' src='/img/about/about-car-2.png' /> */}
            </div>
          </div>
        </div>

        <div className='text-white text-center about-mid-bottom ' >
          <div className='section-title text-center'>
            One-stop centralised place for classic car resources
          </div>
          <div>
            The automobile industry is changing with the time and becoming more challenging., and the classic car culture has begun to have a fault. LPA hopes, through the one-stop centralized place, professional repairment and maintenance centre and auction and investment services, to bring together the classic car resources from all over the world and to promote and pass on the classic car culture.
          </div>
        </div>

      </div>

    </div>
  )
}

export default About