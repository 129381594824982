import LazyImage from '../../shared/LazyImage'
import './Consignment.css'
import React, { useState } from 'react'
import TermsPreviewModal from './TermsModal';
import { getContactUsForm, validateContactUsForm, saveContactUs } from '../../shared/util'
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import ButtonLoader from '../../shared/ButtonLoader';

const Consignment = () => {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false)
  const [terms, setTerms] = useState(false)

  const handleSubmitForm = async result => {
    if (terms === false)
      toast("Please agree terms and conditions", { type: 'error' })
    else if (validateContactUsForm(result) === true) {
      setLoading(true)
      saveContactUs(result)
        .then(response => response.data)
        .then(data => {
          setLoading(false)
          toast("Form submitted successfully", { type: 'success' })
          reset()
        })
        .catch(err => {
          console.log(err);
          setLoading(false)
          toast("Some error in form submission. Please try again !", { type: 'error' })
        });
    }
  }

  const { register, handleSubmit, reset } = useForm({
    defaultValues: JSON.parse(JSON.stringify(getContactUsForm('contact_us')))
  });

  return (
    (
      <div>
        {/* <Modal modalSize="full" show={showModal} onClose={() => setShowModal(false)} title="Terms and condition">
          Some content
        </Modal> */}

        <div className='consignment-container' >
          <LazyImage src='/img/consignment/consignment-banner-1.png' className='consignment-banner-img' alt="ConsignmentCar" loading="Loading" />
          {/* <img src='/img/consignment/consignment-banner-1.png' className='consignment-banner-img' alt="ConsignmentCar" loading="Loading"/> */}
          <div className='consignment-bottom-overlay' >
            <div className='consignment-title'>
              Consign with us and we make the most for your cars
            </div>
            <div className='consignment-sub'>
              We consign all types of Cars. We have a vast network of buyers and our co-operation with auction house provide you with unmatched exposure for your car. We take care of the details. From start to finish, the entire sales transaction is handled by our experienced sales team.
            </div>
          </div>
        </div>

        <div id='mid-section' className='py-4' >
          <div className='consignment-form-container px-5 py-4' >
            <div className='consignment-title'>
              Invitation to consign with us!
            </div>
            <div className='consignment-sub'>
              We invite you to share our success with the opportunity to consign with us!
            </div>
            <form onSubmit={handleSubmit(handleSubmitForm)} className='row mt-5'>
              <div className='col-md-6 mt-4'>
                <input type="text" className="form-control no-border" {...register('name', { required: true })} placeholder="Name" />
              </div>
              <div className='col-md-6 mt-4'>
                <input type="text" className="form-control no-border" {...register('phone', { required: false })} placeholder="Phone" />
              </div>
              <div className='col-md-6 mt-4'>
                <input type="text" className="form-control no-border" {...register('email', { required: false })} placeholder="Email" />
              </div>
              <div className='col-md-6 mt-4'>
                <input type="text" className="form-control no-border" {...register('vehicle_location', { required: false })} placeholder="Vehicle Location" />
              </div>

              <div className='col-md-12 mt-5'>
                <label className='float-start mb-3'>How much service history does your car have?*</label>
                <select class="form-select " aria-label="Default select example" {...register('service_history', { required: false })}>
                  <option value="">Select an option</option>
                  <option value="full">Full</option>
                  <option value="partial">Partial</option>
                  <option value="none">None</option>
                </select>
              </div>

              <div class="form-check mt-5 text-start col-md-12 mx-3">
                <input class="form-check-input" type="checkbox" id="terms" checked={terms} onClick={e => setTerms(!terms)} />
                <label class="form-check-label" for="terms">
                  <span>I agree to the </span>
                </label>&nbsp;
                <span className='text-yellow cursor-pointer' onClick={() => setShowModal(true)}>terms and conditions*</span>
              </div>

              <button className='btn btn-sm btn-primary-new pull-left mt-5 mx-3' style={{ 'width': '250px' }} type='submit'>
                {/* CONTACT US &nbsp;&nbsp; <i className="fa fa-long-arrow-right" aria-hidden="true"></i> */}
                {
                  !loading && (
                    <span>
                      CONTACT US &nbsp;&nbsp; <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                    </span>
                  )
                }
                {
                  loading && <ButtonLoader />
                }
              </button>
            </form>
          </div>
        </div>

        <div className='consignment-container' >
          <LazyImage src='/img/consignment/consignment-banner-2.png' className='consignment-banner-img-2' alt="ConsignmentCar" loading="Loading" />
          {/* <img src='/img/consignment/consignment-banner-2.png' className='consignment-banner-img-2' alt="ConsignmentCar" loading="Loading" /> */}
          <div className='consignment-bottom-overlay'>
            <div className='consignment-title'>
              Auction
            </div>
            <div className='consignment-sub'>
              League of Preizoso Auto partner with the world most renowned auction house and connect your luxury or exotic cars with the best potential buyers!
            </div>
            <div className='banner-btn-container'>
              <button className='btn btn-sm btn-primary-new pull-left mt-5' >
                AUCTION HOUSE &nbsp;&nbsp; <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </div>

        <TermsPreviewModal
          isOpen={showModal}
          closeModal={() => setShowModal(false)}
        />
      </div>
    )
  )
}

export default Consignment