import React from 'react'
import './HomePage.css'

const CarCard = props => {
  return (
    <div className='car-card'>
      <img src={props.src}  className="car-card-image" alt={props.title} loading={props.title}/>
      <div className='bottom-left'>
        | <span style={{ marginLeft: '20px' }}>{props.title}</span>
      </div>
    </div>
  )
}


export default CarCard