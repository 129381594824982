import React from 'react'
import './CarList.css';

const CarVideos = props => {
  return (
    <div>
      {
        props.videos.length === 0 && (
          <span className="text-center">
            No Videos
          </span>
        )
      }
      {
        props.videos.map(video => (
          <div className="video-container">
            <video controls id="myVideo">
              <source src={video} type="video/mp4" />
            </video>
            <div className="video-loader">
              <div className="loader"></div>
            </div>
          </div>
        ))
      }
    </div>
  )
}

export default CarVideos