import React from 'react'

const Loader = () => {
  return (
    <div className='py-5'>
      <div className="spinner-border text-light my-2" role="status">
      </div>
      <div className=" text-white">Loading...</div>
    </div>
  )
}

export default Loader