import React from 'react'
import { useNavigate } from 'react-router';
import LazyImage from '../../shared/LazyImage'
import './CarList.css'
import { getCarImages } from '../../shared/util';

const CarCard = props => {
  const navigate = useNavigate();

  const redirect = car => {
    navigate('/main/car-details', {
      state: car
    });
  }

  return (
    <div className='p-3' onClick={() => redirect(props.car)} style={{ 'cursor': 'pointer' }}>
      <LazyImage src={getCarImages(props.car)[0]} className='car-list-image' />
      <div className='car-title py-2'>
        {props.car.brand} - {props.car.name}
      </div>
      <div className='row'>
        <span className='col-md-6 col-sm-6 col-sm-6 car-sub-title text-start'>
          {props.car.price_currency} {props.car.price_amount}
        </span>
        <span className='col-md-6 col-sm-6 col-sm-6 car-sub-title text-end'>
          {props.car.year} | {props.car.mileage}
        </span>
      </div>
    </div>
  )
}

export default CarCard